import k from './keys';

export default {
  [k.TITLE]: '麦肯锡设计指数',
  [k.MAIN_SUBTITLE]: '麦肯锡设计指数（MDI）在线工具可以让领导者在一小时内评估其公司的设计绩效，与300多家其他公司进行基准比较，并估计可通过改进获得的风险值',
  [k.READ_THE_ARTICLE]: '阅读文章',
  [k.WATCH_THE_VIDEO]: '观看MDI视频',
  [k.EMAIL]: '电子邮件',
  [k.CLIENT_ID]: '客户ID',
  [k.RECOVER_CLIENT_ID_BUTTON]: '恢复客户ID',
  [k.CONTINUE]: '继续',
  [k.TOS_AGREE]: '点击“继续”即表示我同意设指数的服务条款',
  [k.COOKIE_DISCLAIMER]: '继续浏览或使用我们的网站，即表示您同意我们可以存储和使用Cookie和其他跟踪技术，如次Cookie政策所述。',
  [k.INTRO_TEXT]: '这项调研所需时间为一个小时以内。这是对您组织如何使用设计的一个彻底检验。',
  [k.START_SURVEY]: '开始评估',
  [k.NEXT_BUTTON]: '下一项',
  [k.PREVIOUS_BUTTON]: '前一项',
  [k.LOGOUT_BUTTON]: '登出',
  [k.RESULT_BUTTON]: '结果',
  [k.ALL_SAVED]: '全部保存',
  [k.TEXT_FIELD]: '请在这里输入您的回答',
};
