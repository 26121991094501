import k from './keys';

export default {
  [k.TITLE]: 'McKinsey Design Index',
  [k.MAIN_SUBTITLE]:
    'The McKinsey Design Index (MDI) online tool allows leaders to assess their company’s design performance in under an hour, benchmark it with over 300 other companies, and estimate the value-at-stake through improvement.',
  [k.READ_THE_ARTICLE]: 'Read the article',
  [k.WATCH_THE_VIDEO]: 'Watch the MDI video',
  [k.EMAIL]: 'Email',
  [k.CLIENT_ID]: 'Password',
  [k.RECOVER_CLIENT_ID_BUTTON]: 'Recover Password',
  [k.CONTINUE]: 'Continue',
  [k.TOS_AGREE]: 'By clicking “Continue” I agree to the Design Index ',
  [k.COOKIE_DISCLAIMER]: 'By continuing to browse or use our Sites, you agree that we can store and access Cookies and Other Tracking Technologies as described in this Cookie Policy. ',
  [k.INTRO_TEXT]: "This survey takes less than an hour. It's a very thorough examination of design in your organization.",
  [k.START_SURVEY]: 'Start Survey',
  [k.NEXT_BUTTON]: 'Next',
  [k.FINISH_BUTTON]: 'Finish',
  [k.PREVIOUS_BUTTON]: 'Previous',
  [k.LOGOUT_BUTTON]: 'Logout',
  [k.RESULT_BUTTON]: 'Result',
  [k.ALL_SAVED]: 'All saved',
  [k.BACK_TO_SURVEY]: 'Back to survey',
  [k.YOU_ARE_OFFLINE]: 'You are offline',
  [k.TEXT_FIELD]: 'Please enter your answer here',
  [k.DESIGN]: 'Design',
  [k.WHAT_IS_DESIGN]: 'The process of understanding user needs, and then creating products and services to meet those needs. This covers physical, digital and service design, and is not just about aesthetics.',
  [k.DESIGNERS]: 'Designers',
  [k.WHO_ARE_DESIGNERS]: 'Professionals who work in product and service ideation, concepting, prototyping and detailed design. This can also include insight generation from user data/research.',
  [k.THROUGHOUT_THE_SURVEY]: 'Throughout this survey the definition of',
  [k.IMPORTANT]: 'Important',
  [k.WELCOME_BACK_MESSAGE]: `
  Good news! As of 1st August 2019 the McKinsey Design Index moved from Beta to v1.0.

  As part of our ongoing efforts to maintain the MDI as the most accurate and comprehensive design performance tool available, we have updated the scoring based on thousands of new data points from companies around the world. 
  
  As always, if you have any questions or would like to discuss your results, please feel free to get in touch with us at Designindex_Admin@mckinsey.com
  `,
  [k.WELCOME_BACK_SCORE_CHANGED]: `
  Good news! As of 1st August 2019 the McKinsey Design Index moved from Beta to v1.0.

  As part of our ongoing efforts to maintain the MDI as the most accurate and comprehensive design performance tool available, we have made updates to the scoring based on thousands of new data points from companies around the world. 
   
  You will therefore see a small movement in some of your scores, based on the new, larger dataset. The MDI survey questions have not changed and there is no need to retake the survey to view your score.
   
  As always, if you have any questions or would like to discuss your results, please feel free to get in touch with us at Designindex_Admin@mckinsey.com.
`,
  [k.SUBMIT_PASSWORD_RESET]: 'SUBMIT',
  [k.NEW_PASSWORD]: 'New password',
  [k.CONFIRM_NEW_PASSWORD]: 'Confirm new password',
};
