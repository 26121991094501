import axios from 'axios';
import FileSaver from 'file-saver';
import merge from 'lodash/merge';
import get from 'lodash/get';
import StorageService from './StorageService';

const ADMIN_TIMEOUT = 30 * 60 * 1000; // 30 mins
const USER_TIMEOUT = 30 * 1000; // 30 secs

class API {
  constructor() {
    const baseURL = {
      development: '/',
      staging: '/design-index-stg',
      production: '/design-index',
    }[process.env.REACT_APP_BUILD_ENV];
    this.instance = axios.create({
      baseURL,
      timeout: USER_TIMEOUT,
    });
  }

  setInstance(instance) {
    this.instance = instance;
  }

  downloadReport = async () => {
    const header = merge(this.getAuthHeaders(), { responseType: 'blob' });
    try {
      const response = await this.instance.get('/api/report.pdf', header);
      const { data } = response;
      FileSaver.saveAs(data, 'report.pdf');
      return data;
    } catch (err) {
      throw err;
    }
  }

  getQuestions = async () => {
    try {
      const language = localStorage.getItem('language');
      const response = await this.instance.get(`/api/questions?language=${language}`, this.getAuthHeaders());
      await this.refreshTokens(response);
      return response;
    } catch (err) {
      this.handleNotAuthorized(err);
      throw err;
    }
  }

  getAnswers = async () => {
    let response = {};
    try {
      response = await this.instance.get('/api/answers', this.getAuthHeaders());
      await this.refreshTokens(response);
      return response;
    } catch (err) {
      this.handleNotAuthorized(err);
      throw err;
    }
  }

  getResults = async () => {
    try {
      const response = await this.instance.get('/api/answers/score', this.getAuthHeaders());
      await this.refreshTokens(response);
      return response;
    } catch (err) {
      this.handleNotAuthorized(err);
      throw err;
    }
  }

  async callApiGetAnswers() {
    const res = await this.getAnswers();
    const answersResponse = get(res, 'data.answers', []);
    const answersMap = {};

    // Restructure answers here
    answersResponse.forEach((obj) => {
      const qid = obj.question_id;
      let alternative = obj.alternative_id;
      if (Array.isArray(alternative)) {
        // its multi select type question
        const newAlternative = {};
        alternative.forEach((key) => {
          newAlternative[key] = true;
        });
        alternative = newAlternative;
      } else {
        alternative = { id: alternative };
      }
      answersMap[qid] = {
        question: { id: qid },
        alternative,
        text: get(obj, 'text'),
      };
    });
    return answersMap;
  }

  saveAnswer = async (question, alternatives, text) => {
    const url = '/api/answers';
    const params = {
      answer: {
        question,
        alternatives,
        text,
      },
    };
    try {
      const response = await this.instance.post(url, params, this.getAuthHeaders());
      await this.refreshTokens(response);
      return response;
    } catch (err) {
      this.handleNotAuthorized(err);
      throw err;
    }
  }

  async login(rawEmail, rawPassword, captcha) {
    this.logout();
    const url = '/api/login';
    const preprocess = maybeString => String(maybeString).trim();
    const email = preprocess(rawEmail).toLowerCase();
    const password = preprocess(rawPassword);
    const res = await this.instance.post(url, this.getAuthParams(email, password, captcha));
    const {
      jwt, client_type, ab_test, show_popup, // eslint-disable-line camelcase
    } = res.data;
    return this.saveTokens(jwt, client_type, ab_test, show_popup);
  }

  async checkCaptchaNeeded() {
    const response = await this.instance.get('/api/captcha');
    const { captcha_required: isCaptchaNeeded } = response.data; // eslint-disable-line camelcase
    return isCaptchaNeeded;
  }

  async signUp(rawEmail, captcha) {
    this.logout();
    const preprocess = maybeString => String(maybeString).trim();
    const email = preprocess(rawEmail).toLowerCase();
    const res = await this.instance.post('/api/users/sign_up', { user: { email, captcha } });
    const {
      jwt, client_type, ab_test, show_popup, // eslint-disable-line camelcase
    } = res.data;
    return this.saveTokens(jwt, client_type, ab_test, show_popup);
  }

  handleNotAuthorized(err) {
    if (!err.response || err.response.status !== 401) return;
    window.location.href = '/login';
    this.logout();
  }

  isLoggedIn() {
    return !!this.getAuthToken();
  }

  logout = () => {
    StorageService.remove('questions');
    StorageService.remove('answersMap');
    StorageService.remove('answers');
    StorageService.remove('ab_test');
    StorageService.remove('show_popup');

    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('client_type');
  }

  saveTokens = (token, clientType, abTest, showPopup) => {
    if (!token) {
      console.error('[saveTokens] token is undefined');

      return null;
    }
    sessionStorage.setItem('auth_token', token);
    sessionStorage.setItem('client_type', clientType.toLowerCase());

    StorageService.save('ab_test', abTest);
    StorageService.save('show_popup', showPopup);

    return token;
  }

  getAuthToken = () => sessionStorage.getItem('auth_token')

  getAuthParams = (email, password, captcha) => ({
    auth: {
      email,
      password,
      captcha,
    },
  })

  getAuthHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    };
  }

  getAdminAuthHeaders() {
    return merge(this.getAuthHeaders(), { timeout: ADMIN_TIMEOUT });
  }

  refreshTokens = (res) => {
    const { authorization } = res.headers;
    if (!authorization) {
      console.error(`[refreshTokens] authorization is undefined ${JSON.stringify(res.headers, null, 2)}`);

      return res;
    }
    sessionStorage.setItem('auth_token', authorization);
    return res;
  }

  async adminDashboard(offset, search, organisationId, onlyScores = 'false', secondary = 'null') {
    const response = await this.instance.get(`/api/accounts?offset=${offset}&search=${encodeURIComponent(search)}&organisation_id=${organisationId}&only_scores=${onlyScores}&secondary=${secondary}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  resetAttempts = async (accountId) => {
    const payload = {
      account_id: accountId,
    };
    const response = await this.instance.post('/api/users/reset_attempts', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async signUpAdmin(emails, type, organisationId, inviteEmailDetailsWithHTMLBody) {
    const payload = {
      emails,
      type,
      organisation_id: organisationId,
      invite_email_details: inviteEmailDetailsWithHTMLBody,
    };
    const response = await this.instance.post('/api/users/sign_up_many', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response;
  }

  async getEmails() {
    const response = await this.instance.get('/api/users/emails', this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async getUserStatus(id) {
    const response = await this.instance.get(`/api/users/status?id=${id}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async deleteUser(id) {
    const response = await this.instance.delete(`/api/users?id=${id}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.status;
  }

  async getOrganisationNames() {
    const response = await this.instance.get('/api/organisations', this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async updateAccount(payload) {
    const response = await this.instance.post('/api/accounts/change_client_type', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async changeHideRecord(payload) {
    const response = await this.instance.post('/api/accounts/change_hide_record', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async addAnotherClientCode(emails, type, organisationId, inviteEmailDetailsWithHTMLBody) {
    const payload = {
      emails,
      type,
      organisation_id: organisationId,
      invite_email_details: inviteEmailDetailsWithHTMLBody,
    };
    const response = await this.instance.post('/api/accounts/another_client_code', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response;
  }

  async addOrganisation(name) {
    const response = await this.instance.post('/api/organisations', { name }, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async checkIfOrganisationHasClients(id) {
    const response = await this.instance.get(`/api/organisations/client_present?id=${id}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  async deleteOrganisation(id) {
    const response = await this.instance.delete(`/api/organisations?id=${id}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.status;
  }

  async getPdfReport(accountId, type, secondary) {
    const typeName = {
      1: 'default',
      2: 'deep_dive',
      3: 'aggregate',
    }[type];

    const response = await this.instance
      .get(`/api/report.pdf?account_id=${accountId}&type=${typeName}&secondary=${!!secondary}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    return response.data;
  }

  async getCsvReport(accountId) {
    const response = await this.instance
      .get(`/api/answers/csv?account_id=${accountId}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    return response.data;
  }

  async getOrganisationCsvReport(organisationId) {
    const response = await this.instance
      .get(`/api/report/csv/organisation?organisation_id=${organisationId}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    return response.data;
  }

  async getOrganisationAnswersCsvReport(organisationId) {
    const response = await this.instance
      .get(`/api/answers/csv_organisation?organisation_id=${organisationId}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    return response.data;
  }

  getCombinedCsvReport = async (organisationId) => {
    const response = await this.instance
      .get(`/api/report/csv/combined?organisation_id=${organisationId}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    return response.data;
  }

  async getOrganisationPdfReport(organisationId) {
    const response = await this.instance
      .get(`/api/report_organisation.pdf?organisation_id=${organisationId}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));
    return response.data;
  }

  async sendEmailReminder({ organisationId, reminderEmailDetailsWithHTMLBody }) {
    const payload = {
      organisation_id: organisationId,
      reminder_email_details: reminderEmailDetailsWithHTMLBody,
    };
    const response = await this.instance.post('/api/users/send_reminder_mail', payload, this.getAdminAuthHeaders());

    await this.refreshTokens(response);
    return response.data;
  }

  async getCsvReportHistorical(email) {
    const response = await this.instance
      .get(`/api/answers/csv_historical?email=${email}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    FileSaver.saveAs(response.data, `answers_historical_${email}.csv`);

    return response;
  }

  async getPdfReportHistorical(email) {
    const response = await this.instance
      .get(`/api/report_historical.pdf?email=${email}`,
        merge(
          this.getAdminAuthHeaders(),
          { responseType: 'blob' },
        ));

    FileSaver.saveAs(response.data, `historical_${email}.pdf`);

    return response;
  }

  async getAllReport(offset) {
    const response = await this.instance
      .get(`/api/report/csv?offset=${offset}`,
        merge(
          this.getAdminAuthHeaders(),
        ));
    await this.refreshTokens(response);
    return response;
  }

  async getNumberToFetchForAllCsv() {
    const response = await this.instance
      .get('/api/report/csv_number',
        merge(
          this.getAdminAuthHeaders(),
        ));
    await this.refreshTokens(response);
    return response;
  }

  async recoverClientId(rawEmail, captcha) {
    const preprocess = maybeString => String(maybeString).trim();
    const email = preprocess(rawEmail).toLowerCase();
    const response = await this.instance.post('/api/users/recover_client_id', { user: { email, captcha } });
    return response;
  }

  recoverPassword = async (rawEmail, captcha) => this.instance.post('/api/users/reset_password', { is_reset: true, email: String(rawEmail).trim().toLowerCase(), captcha });

  resendClientIds = async (accounts, sendOnlyToAdmin) => {
    const payload = {
      emails: accounts,
      send_only_to_admin: sendOnlyToAdmin,
    };
    const response = await this.instance.post('/api/accounts/resend_client_id', payload, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response;
  };

  checkConnectivity = async () => {
    try {
      const response = await this.instance.get('/api/check_connection', merge(
        this.getAuthHeaders(),
        { timeout: 2000 },
      ));
      await this.refreshTokens(response);
      return response;
    } catch (err) {
      this.handleNotAuthorized(err);
      throw err;
    }
  }

  async deleteAccount(id) {
    const response = await this.instance.delete(`/api/accounts?id=${id}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response;
  }

  logScheduleWorkshop = async () => {
    const response = await this.instance.get('/api/schedule_workshop_logs/log', merge(this.getAuthHeaders()));
    await this.refreshTokens(response);
    return response;
  }

  sendMessage = async (payload) => {
    const response = await this.instance.post('/api/accounts/send_message_email', payload, merge(this.getAuthHeaders()));
    await this.refreshTokens(response);
    return response;
  }

  getStatistics = async () => {
    const response = await this.instance.get('/api/statistics', merge(this.getAdminAuthHeaders()));
    await this.refreshTokens(response);
    return response.data;
  }

  getLoginLogs = async (offset) => {
    const response = await this.instance.get(`/api/login_logs?offset=${offset}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  getAdminLogs = async (offset) => {
    const response = await this.instance.get(`/api/admin_logs?offset=${offset}`, this.getAdminAuthHeaders());
    await this.refreshTokens(response);
    return response.data;
  }

  addToWatchlist = async (accountId, selected) => {
    const payload = {
      account_id: accountId,
      selected,
    };
    let response = '';

    if (selected) {
      response = await this.instance.put('/api/watchlist/add_to_watchlist', payload, this.getAdminAuthHeaders());
    } else {
      response = await this.instance.delete('/api/watchlist/remove_from_watchlist', merge(
        this.getAdminAuthHeaders(),
        { data: { account_id: accountId } },
      ));
    }
    await this.refreshTokens(response);
    return response.data;
  }

  validateResetPassword = token => this.instance.get(
    `/api/users/reset_password/${token}`,
  );

  submitPassword = (password, token) => this.instance.put('/api/users/reset_password', {
    new_password: password,
    token,
  });
}


const api = new API();
export default api;
