export default {
  TITLE: 'TITLE',
  MAIN_SUBTITLE: 'MAIN_SUBTITLE',
  READ_THE_ARTICLE: 'READ_THE_ARTICLE',
  WATCH_THE_VIDEO: 'WATCH_THE_VIDEO',
  EMAIL: 'EMAIL',
  CLIENT_ID: 'CLIENT_ID',
  RECOVER_CLIENT_ID_BUTTON: 'RECOVER_CLIENT_ID_BUTTON',
  CONTINUE: 'CONTINUE',
  TOS_AGREE: 'TOS_AGREE',
  COOKIE_DISCLAIMER: 'COOKIE_DISCLAIMER',
  INTRO_TEXT: 'INTRO_TEXT',
  START_SURVEY: 'START_SURVEY',
  NEXT_BUTTON: 'NEXT_BUTTON',
  FINISH_BUTTON: 'FINISH_BUTTON',
  PREVIOUS_BUTTON: 'PREVIOUS_BUTTON',
  LOGOUT_BUTTON: 'LOGOUT_BUTTON',
  RESULT_BUTTON: 'RESULT_BUTTON',
  BACK_TO_SURVEY: 'BACK_TO_SURVEY',
  ALL_SAVED: 'ALL_SAVED',
  YOU_ARE_OFFLINE: 'YOU_ARE_OFFLINE',
  TEXT_FIELD: 'TEXT_FIELD',
  DESIGN: 'DESIGN',
  WHAT_IS_DESIGN: 'WHAT_IS_DESIGN',
  DESIGNERS: 'DESIGNERS',
  WHO_ARE_DESIGNERS: 'WHO_ARE_DESIGNERS',
  THROUGHOUT_THE_SURVEY: 'THROUGHOUT_THE_SURVEY',
  IMPORTANT: 'IMPORTANT',
  WELCOME_BACK_MESSAGE: 'WELCOME_BACK_MESSAGE',
  WELCOME_BACK_SCORE_CHANGED: 'WELCOME_BACK_SCORE_CHANGED',
  SUBMIT_PASSWORD_RESET: 'SUBMIT_PASSWORD_RESET',
  NEW_PASSWORD: 'NEW_PASSWORD',
  CONFIRM_NEW_PASSWORD: 'CONFIRM_NEW_PASSWORD',
};
