import React from 'react';
import {
  Wrapper, LoadingAnimation, GapDiv, LoadingAnimationContainer,
} from './styles';

const Loading = () => (
  <Wrapper>
    <LoadingAnimationContainer>
      <LoadingAnimation />
      <GapDiv />
      <LoadingAnimation />
      <GapDiv />
      <LoadingAnimation />
      <GapDiv />
      <LoadingAnimation />
    </LoadingAnimationContainer>
  </Wrapper>
);

export default Loading;
