import k from './keys';

export default {
  [k.TITLE]: 'Índice de diseño de McKinsey',
  [k.MAIN_SUBTITLE]: 'El Índice de diseño de McKinsey (MDI) es una herramienta digital que permite a líderes de compañías evaluar su desempeño en diseño y centricidad en el cliente en menos de una hora, comparándolas con otras compañías en más de 300 rubros y estimando el valor en juego a través de mejorías.',
  [k.READ_THE_ARTICLE]: 'Leer al artículo',
  [k.WATCH_THE_VIDEO]: 'Ver el video del MDI',
  [k.EMAIL]: 'Email',
  [k.CLIENT_ID]: 'ID de cliente',
  [k.RECOVER_CLIENT_ID_BUTTON]: 'Recuperar ID',
  [k.CONTINUE]: 'Continuar',
  [k.TOS_AGREE]: 'Dando clic en "seguir" yo acepto los términos de servicio del Índice de Diseño (MDI)',
  [k.COOKIE_DISCLAIMER]: 'Continuando a usar nuestras páginas, aceptas que guardemos y tengamos acceso a Cookies y otras tecnologías de rastreo descritas en esta Política de Cookies.',
  [k.INTRO_TEXT]: 'Esta encuesta tarda menos de una hora. Es una examinación exhaustiva del diseño dentro de tu organización.',
  [k.START_SURVEY]: 'Empezar Encuesta', //
  [k.NEXT_BUTTON]: 'Siguiente',
  [k.FINISH_BUTTON]: 'Terminar', //
  [k.PREVIOUS_BUTTON]: 'Previa',
  [k.LOGOUT_BUTTON]: 'Salir',
  [k.RESULT_BUTTON]: 'Resultado',
  [k.ALL_SAVED]: 'Todo guardado',
  [k.BACK_TO_SURVEY]: 'Volver a la encuesta', //
  [k.YOU_ARE_OFFLINE]: 'Estas desconectado', //
  [k.TEXT_FIELD]: 'Por favor escribir respuesta aquí',
  [k.DESIGN]: 'Diseño',
  [k.WHAT_IS_DESIGN]: 'El proceso de entender necesidades de usuario, y de crear productos y servicios para satisfacerlas. Esto cubre diseño físico, digital y de servicios, y va más allá de la estética.',
  [k.DESIGNERS]: 'Diseñadores',
  [k.WHO_ARE_DESIGNERS]: 'Profesionales que trabajan en la ideación, conceptualización, prototipado y diseño detallado de productos y servicios. Esto también incluye la generación de conocimientos y perspectivas provenientes de investigación y datos.',
  [k.THROUGHOUT_THE_SURVEY]: 'A través de esta encuesta la definición de diseño es',
  [k.IMPORTANT]: 'Importante',
  [k.WELCOME_BACK_MESSAGE]: `
  Good news! As of 1st August 2019 the McKinsey Design Index moved from Beta to v1.0.

  As part of our ongoing efforts to maintain the MDI as the most accurate and comprehensive design performance tool available, we have updated the scoring based on thousands of new data points from companies around the world. 
  
  As always, if you have any questions or would like to discuss your results, please feel free to get in touch with us at Designindex_Admin@mckinsey.com
  `,
  [k.WELCOME_BACK_SCORE_CHANGED]: `
  Good news! As of 1st August 2019 the McKinsey Design Index moved from Beta to v1.0.

  As part of our ongoing efforts to maintain the MDI as the most accurate and comprehensive design performance tool available, we have made updates to the scoring based on thousands of new data points from companies around the world. 
   
  You will therefore see a small movement in some of your scores, based on the new, larger dataset. The MDI survey questions have not changed and there is no need to retake the survey to view your score.
   
  As always, if you have any questions or would like to discuss your results, please feel free to get in touch with us at Designindex_Admin@mckinsey.com.
`,
};
