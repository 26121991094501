import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import api from '../services/API';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = api.isLoggedIn();

  return (
    <Route
      {...rest}
      render={p => (isLoggedIn ? (
        <Component {...p} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: p.location } }} />
      ))
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.shape({}).isRequired,
};

export default PrivateRoute;
