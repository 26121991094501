export default {
  green: '#2246c2',
  admin: {
    grey: '#a2a2a2',
    white: '#ffffff',
    black: '#000000',
    primaryButton: '#03dddd',
    secondaryButton: '#0146e5',
    placeholder: '#BFBFBF',
    optionsColor: '#17161c',
    optionsBackground: '#ffffff',
  },
  primary: '#ffffff',
  quartile1: '#3C96B4',
  quartile2: '#AAE6F0',
  quartile3: '#1F40E6',
  quartile4: '#00A9F4',
  inputText: {
    primary: '#ffffff',
    error: '#ff5252',
    neutralBorder: '#999999',
    white: '#ffffff',
  },
  placeholder: '#dedee0',
};
