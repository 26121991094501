import React, { Component, Suspense, lazy } from 'react';
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import Loading from './components/Loading';
import PrivateRoute from './utils/PrivateRoute';
import api from './services/API';
import './App.css';
import './fonts/Roboto.css';
import './fonts/Montserrat.css';
import './fonts/Bower.css';
import './fonts/TheinhardtPan.css';

const Admin = lazy(() => import('./containers/Admin'));
const Home = lazy(() => import('./containers/Home'));
const Login = lazy(() => import('./containers/Login'));
const Result = lazy(() => import('./containers/Result'));
const Survey = lazy(() => import('./containers/Survey'));
const NotFoundPage = lazy(() => import('./containers/NotFoundPage'));
const FinishSurvey = lazy(() => import('./containers/FinishSurvey'));
const EmailManagement = lazy(() => import('./containers/EmailManagement'));
const ServerErrorPage = lazy(() => import('./containers/ServerErrorPage'));
const Statistics = lazy(() => import('./containers/Statistics'));
const Logs = lazy(() => import('./containers/Logs'));
const ResetPassword = lazy(() => import('./containers/ResetPassword'));


class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-127835836-1');
    ReactGA.pageview('test-init-pageview');

    // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
    this.basename = {
      development: '',
      staging: 'design-index-stg',
      production: 'design-index',
    }[process.env.REACT_APP_BUILD_ENV];
  }

  redirectToLoginIfLoggedOff = () => {
    if (!api.isLoggedIn()) {
      return <Redirect to={`${this.basename}/login`} />;
    }

    return null;
  }

  render() {
    return (
      <Router basename={`/${this.basename}`}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <PrivateRoute exact path="/survey/q/:questionId" component={Survey} />
            <PrivateRoute path="/result" component={Result} />
            <PrivateRoute path="/finish" component={FinishSurvey} />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/emails" component={EmailManagement} />
            <PrivateRoute path="/statistics" component={Statistics} />
            <PrivateRoute path="/logs" component={Logs} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/500" component={ServerErrorPage} />
            <Route exact path="/" component={Home} />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
