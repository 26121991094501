import 'core-js/stable';
import 'unorm';
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import english from './i18n/english';
import mandarin from './i18n/mandarin';
import portuguese from './i18n/portuguese';
import japanese from './i18n/japanese';
import spanish from './i18n/spanish';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: english,
      },
      'zh-Hans': {
        translation: mandarin,
      },
      pt: {
        translation: portuguese,
      },
      ja: {
        translation: japanese,
      },
      es: {
        translation: spanish,
      },
    },
    lng: localStorage.getItem('language'),
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
