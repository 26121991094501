import styled, { keyframes } from 'styled-components';
import background from '../../assets/blurred-background.png';
import colors from '../../constants/colors';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  background-image: url(${background});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const loading = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
`;

export const LoadingAnimation = styled.div`
  display: inline-block;
  width: 4px;
  height: 50px;
  border-radius: 4px;
  animation: ${loading} 1s ease-in-out infinite;
`;

export const LoadingAnimationContainer = styled.div`
  & > div:nth-child(1) {
    background-color: ${colors.quartile1};
    animation-delay: 0s;
  }

  & > div:nth-child(3) {
    background-color: ${colors.quartile2};
    animation-delay: 0.09s;
  }

  & > div:nth-child(5) {
    background-color: ${colors.quartile3};
    animation-delay: 0.18s;
  }

  & > div:nth-child(7) {
    background-color: ${colors.quartile4};
    animation-delay: 0.27s;
  }
`;

export const GapDiv = styled.div`
  height: 100%;
  display: inline-block;
  width: 20px;
`;
