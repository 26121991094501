import k from './keys';

export default {
  [k.TITLE]: 'マッキンゼー・デザイン・インデックス',
  [k.MAIN_SUBTITLE]: 'マッキンゼー・デザイン・インデックス(MDI)は企業のリーダーが自社のデザインパフォーマンスを1時間以内に評価、300社とのベンチマークを実施し、改善を通じたバリューアットステーク（経済価値）を予測することができるオンラインツールです。',
  [k.READ_THE_ARTICLE]: '記事を読む',
  [k.WATCH_THE_VIDEO]: 'MDIビデオを見る',
  [k.EMAIL]: 'メール',
  [k.CLIENT_ID]: 'クライアントID',
  [k.RECOVER_CLIENT_ID_BUTTON]: 'クライアントIDを復旧する',
  [k.CONTINUE]: '次へ',
  [k.TOS_AGREE]: '「続ける」をクリックすることで、デザインインデックスのサービス条項に同意します。',
  [k.COOKIE_DISCLAIMER]: 'このサイトの利用を継続すること で、当Cookie規定に記載されている通り、Cookie およびその他のトラッキング技術の使用に同意します。',
  [k.INTRO_TEXT]: '当サーベイは貴組織におけるデザインの詳細な調査であり、所要時間は約1時間です。',
  [k.START_SURVEY]: 'Start Survey',
  [k.NEXT_BUTTON]: '次へ',
  [k.FINISH_BUTTON]: 'Finish',
  [k.PREVIOUS_BUTTON]: '前へ',
  [k.LOGOUT_BUTTON]: 'ログアウト',
  [k.RESULT_BUTTON]: '結果',
  [k.ALL_SAVED]: '全て保存済み',
  [k.BACK_TO_SURVEY]: 'Back to survey',
  [k.YOU_ARE_OFFLINE]: 'You are offline',
  [k.TEXT_FIELD]: 'こちらに回答を入力してください',
  [k.DESIGN]: 'デザイン',
  [k.WHAT_IS_DESIGN]: 'ユーザーのニーズを理解し、当ニーズを満たす製品やサービスを開発するプロセス。美学だけでなく、物理的、デジタルおよびサービスデザインも含む。',
  [k.DESIGNERS]: 'デザイナー',
  [k.WHO_ARE_DESIGNERS]: '製品およびサービスの考案、コンセプトの創出、プロトタイプの作成および詳細な設計に従事するプロフェッショナル。これにはユーザーデータ・調査からの洞察の導出も含む。',
  [k.THROUGHOUT_THE_SURVEY]: '当サーベイにおけるデザインの定義は次の通りです。',
  [k.IMPORTANT]: '重要',
};
