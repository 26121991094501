import k from './keys';

export default {
  [k.TITLE]: '(pt) McKinsey Design Index',
  [k.MAIN_SUBTITLE]: '(pt) The McKinsey Design Index (MDI) online tool allows leaders to assess their company’s design performance in under an hour, benchmark it with over 300 other companies, and estimate the value-at-stake through improvement.',
  [k.READ_THE_ARTICLE]: '(pt) Read the article',
  [k.WATCH_THE_VIDEO]: '(pt) Watch the MDI video',
  [k.EMAIL]: '(pt) Email',
  [k.CLIENT_ID]: '(pt) Password',
  [k.RECOVER_CLIENT_ID_BUTTON]: '(pt) Recover Password',
  [k.CONTINUE]: '(pt) Continue',
  [k.TOS_AGREE]: '(pt) By clicking “Continue” I agree to the Design Index ',
  [k.COOKIE_DISCLAIMER]: '(pt) By continuing to browse or use our Sites, you agree that we can store and access Cookies and Other Tracking Technologies as described in this Cookie Policy. ',
  [k.INTRO_TEXT]: '(pt) This survey takes less than an hour. It\'s a very thorough examination of design in your organization.',
  [k.START_SURVEY]: '(pt) Start Survey',
  [k.NEXT_BUTTON]: '(pt) Next',
  [k.FINISH_BUTTON]: '(pt) Finish',
  [k.PREVIOUS_BUTTON]: '(pt) Previous',
  [k.LOGOUT_BUTTON]: '(pt) Logout',
  [k.RESULT_BUTTON]: '(pt) Result',
  [k.ALL_SAVED]: '(pt) All saved',
  [k.BACK_TO_SURVEY]: '(pt) Back to survey',
  [k.YOU_ARE_OFFLINE]: '(pt) You are offline',
  [k.TEXT_FIELD]: '(pt) Please enter your answer here',
};
